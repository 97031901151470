import { useIntl } from "react-intl";
import { useState, useEffect, useRef } from "react";
import { Tabs, Popover } from "antd";
import { Swiper } from "antd-mobile/es";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import MobileTabs from "./tabs";
import Img from "@/components/Img";
const { TabPane } = Tabs;
import "./style.less";
interface P {
  data: any;
  type: string | undefined | any;
  layout?: string;
  className?: string;
  vertical?: boolean;
}
const SwiperTabs = (props: P) => {
  const {
    data = [],
    type = "line",
    layout = "lr",
    className,
    vertical = false,
  } = props;
  const intl = useIntl();
  const [swiperKey, setSwiperKey] = useState("0");
  const [appWidth, setAppWidth] = useState(false);
  const refSwiper = useRef<SwiperRef>(null);
  useEffect(() => {
    bindResize();
    window.addEventListener("resize", bindResize, true);
    return function cleanup() {
      window.removeEventListener("resize", bindResize);
    };
  }, []);
  useEffect(() => {
    setSwiperKey("0");
    refSwiper.current?.swipeTo(0);
  }, [data]);
  const bindResize = () => {
    //如果地域750，则不展示菜单
    if (window) {
      if (window.innerWidth <= 768) {
        setAppWidth(true);
      } else {
        setAppWidth(false);
      }
    }
  };
  useEffect(() => {
    const a = document.getElementsByClassName("adm-swiper-item-active");
    for (let i = 0; i < a?.length; i++) {
      const obj = a?.[i]?.parentElement;
      if (obj) {
        obj.style.height = "auto";
      }
    }
  }, [swiperKey]);
  const handleChange = (key: string) => {
    refSwiper.current?.swipeTo(Number(key));
    return setSwiperKey(key);
  };
  return (
    <div className={`m-Swiper-body ${className}`}>
      <Tabs
        activeKey={swiperKey}
        onChange={handleChange}
        type={type}
        className={`swiper-tab-${type} swiper-tab-${vertical ? "left" : "top"}`}
        centered={true}
        tabPosition={vertical ? "left" : "top"}
      >
        {data.map((m: any, i: number) => {
          return (
            <TabPane
              tab={
                <Popover content={intl.formatMessage({ id: m.title })}>
                  <div>
                    {m.icon ? (
                      <Img
                        src={m.icon}
                        alt={intl.formatMessage(
                          { id: m?.alt || "" },
                          { platformName: global.__webInfo?.platformName }
                        )}
                      />
                    ) : null}
                    {intl.formatMessage({ id: m.title })}
                  </div>
                </Popover>
              }
              //
              key={i}
            />
          );
        })}
      </Tabs>
      <MobileTabs
        data={data}
        swiperKey={swiperKey}
        handleChange={handleChange}
      />
      <Swiper
        slideSize={appWidth && type === "card" && !vertical ? 86 : 100} //滑块宽度比例
        // style={{
        //   '--border-radius': '8px',
        // }}
        trackOffset={appWidth && type === "card" && !vertical ? 7 : 0}
        defaultIndex={Number(swiperKey)}
        ref={refSwiper}
        onIndexChange={(key: number) => {
          return setSwiperKey(`${key}`);
        }}
        loop={false}
        className={data.length === 1 ? "m-swiper-one" : ""} //如果数据只有一个，做特殊处理，强制不能滑动
        direction={vertical && !appWidth ? "vertical" : "horizontal"}
      >
        {data.map((m: any, i: number) => {
          return (
            <Swiper.Item
              key={i}
              className={
                Number(swiperKey) === i ? "adm-swiper-item-active" : ""
              }
            >
              {layout === "lr" ? (
                <div className="tab-body">
                  <div className="u-tab-left">
                    <Img
                      src={m.img}
                      alt={intl.formatMessage(
                        { id: m.alt },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    />
                  </div>
                  <div className="u-tab-right">
                    <b>
                      {intl.formatMessage(
                        { id: m.title },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    </b>
                    <div className="u-content">
                      {intl.formatMessage(
                        { id: m.content },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {layout === "tb" ? (
                <div className="list-body">
                  <div className="u-tab-list">
                    {m.content ? (
                      <div className="item-li u-tab-imgBox">
                        <div className="u-left">
                          <Img src={m.img} alt={m.alt} />
                        </div>
                        <div className="u-right">
                          <p>
                            {intl.formatMessage(
                              { id: m.content },
                              { platformName: global.__webInfo?.platformName }
                            )}
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {m.list.map((ml: any) => {
                      return (
                        <div key={ml.name} className="item-li">
                          <Img src={ml.img} alt={ml.alt} />
                          <b>
                            {ml?.notIntl
                              ? ml.name
                              : intl.formatMessage(
                                  { id: ml.name },
                                  {
                                    platformName:
                                      global.__webInfo?.platformName,
                                  }
                                )}
                          </b>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {layout === "img" ? (
                <div className="advantages-item">
                  <Img
                    src={m.img}
                    key={i}
                    alt={intl.formatMessage(
                      { id: m.alt },
                      { platformName: global.__webInfo?.platformName }
                    )}
                  />
                </div>
              ) : null}
            </Swiper.Item>
          );
        })}
      </Swiper>
    </div>
  );
};
export default SwiperTabs;
