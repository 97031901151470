import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface Iseo {
  title?: string;
  keywords?: string;
  description?: string;
  icon?: string;
  metas?: any; //头部meta
  protopool?: string;
}

export default function Seo(props: Iseo) {
  const router = useRouter();
  const lang = router.locale || "en-US";
  const { keywords, description, icon, metas, protopool } = props;
  const [href, setHref] = useState<string>("");
  const hreflang: any = {
    "en-US": "en",
    "zh-CN": "zh-cn",
    "zh-HK": "zh-hk",
    "fr-FR": "fr",
    "vi-VN": "vi",
    "ru-RU": "ru",
    "ja-JP": "ja",
  };

  useEffect(() => {
    setHref(window?.location?.href);
  }, []);
  return (
    <>
      <Head>
        {metas}
        <title>{props.title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        {protopool === "https" ? (
          <meta
            httpEquiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          ></meta>
        ) : null}
        <link rel="shortcut icon" href={icon} />
        <link rel="alternate" href={href} lang={hreflang[lang]} />
        <meta property="fb:app_id" content="787090672440172" />
        <meta
          property="og:url"
          content={"https://www.lightnode.com" + router.pathname}
        ></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:site_name" content="LightNode" />
        <meta
          property="og:image"
          content={"https://www.lightnode.com/share.jpg"}
        />
        <meta property="og:image:alt" content="LightNode" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <meta
          name="facebook-domain-verification"
          content="3g3o5g4jc48ivfrb3dy0mx73w9xi3c"
        />
        <meta
          name="google-site-verification"
          content="LEQmuNpKOGD-7-70BXm27Nf7Z8Q6HFkehhrff6wMRSE"
        />
      </Head>
    </>
  );
}
