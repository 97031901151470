import { useIntl } from "react-intl";
import { Tabs } from "antd-mobile/es";
import "./style.less";
interface P {
  swiperKey: string;
  handleChange?: any;
  data: any;
}
const SwiperTabs = (props: P) => {
  const intl = useIntl();
  const { swiperKey, data, handleChange } = props;
  return (
    <Tabs
      activeKey={swiperKey}
      onChange={(key: string) => {
        handleChange?.(key);
      }}
      activeLineMode="fixed"
    >
      {data.map((m: any, i: number) => {
        return (
          <Tabs.Tab
            title={intl.formatMessage(
              { id: m.title },
              { platformName: global.__webInfo?.platformName }
            )}
            key={i}
          />
        );
      })}
    </Tabs>
  );
};
export default SwiperTabs;
