import { useIntl } from "react-intl";
import { Carousel } from "antd";
import Link from "next/link";
import Img from "@/components/Img";
import "./style.less";
// img
import emailWhite from "@/static/images/email-white.png";
import googlewWhite from "@/static/images/google-white.png";
import github from "@/static/images/github.png";
interface P {
  data?: any[];
  content?: any;
  btn?: boolean;
}
const Banner = ({ data = [], content = null, btn = true }: P) => {
  const intl = useIntl();
  return (
    <>
      <div id="m-banner"></div>
      <Carousel autoplay className="m-banner">
        {data?.map((v: any, index: number) => {
          return (
            <div key={index}>
              <Img
                src={v.url}
                alt={intl.formatMessage(
                  { id: v.alt },
                  { platformName: global.__webInfo?.platformName }
                )}
                priority={v.priority ? "high" : "medium"}
              />
              <Img
                src={v.mobileUrl}
                alt={intl.formatMessage(
                  { id: v.mobileAlt },
                  { platformName: global.__webInfo?.platformName }
                )}
                className={"m-banner-mobile"}
                priority={v.priority ? "high" : "medium"}
              />
              {content}
              {btn ? (
                <div className="m-banner-btn">
                  <label
                    className="email"
                    onClick={() => {
                      window.open(global.__webInfo?.registerUrl);
                    }}
                  >
                    <Img
                      src={emailWhite}
                      alt={intl.formatMessage({
                        id: "pages.home.bottom.email",
                      })}
                    />
                    {intl.formatMessage({ id: "pages.home.bottom.email" })}
                  </label>
                  <label
                    onClick={() => {
                      window.open(global.__webInfo?.registerUrl);
                    }}
                  >
                    <Img
                      src={googlewWhite}
                      alt={intl.formatMessage({
                        id: "pages.home.bottom.google",
                      })}
                    />
                    {intl.formatMessage({ id: "pages.home.bottom.google" })}
                  </label>
                  <label
                    onClick={() => {
                      window.open(global.__webInfo?.registerUrl);
                    }}
                  >
                    <Img
                      src={github}
                      alt={intl.formatMessage({
                        id: "pages.home.bottom.github",
                      })}
                    />
                    {intl.formatMessage({ id: "pages.home.bottom.github" })}
                  </label>
                </div>
              ) : null}
            </div>
          );
        })}
      </Carousel>
    </>
  );
};
export default Banner;
